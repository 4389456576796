import React from 'react';
import { graphql } from 'gatsby';
import { Member } from '../TeamSection';
import { TeamList as TeamListDS } from '@solublestudio/idc-design-system';
import { useTranslate } from '@solublestudio/gatsby-theme-soluble-source';

export default function TeamFilterSection({
    results,
    ...props
}: {
    results: Member[];
}): JSX.Element {
    const _ = useTranslate();

    return (
        <TeamListDS
            {...props}
            allCategoriesText={_['filter.all-categories'].text}
            teamMembers={results}
            variant="primary"
        />
    );
}

export const query = graphql`
    fragment DatoCmsTeamFilterSection on DatoCmsTeamFilterSection {
        results {
            ...TeamItem
            categories {
                slug
            }
        }
        categories {
            title
            slug
        }
    }
`;
