import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { Layout } from '@solublestudio/gatsby-theme-soluble-source';

import Section, { PREFIX } from '../sections';

export default function Page({ data }) {
    const sections = useMemo(() => {
        let finalSections = [];
        let skipNext = false;

        data.datoCmsPage?.sections.forEach((item, i) => {
            if (
                item.__typename === `${PREFIX}HeroSectionAlt` &&
                data.datoCmsPage.sections[i + 1]?.__typename ===
                    `${PREFIX}DescriptionSection`
            ) {
                finalSections.push({
                    order: i + 1,
                    __typename: `${PREFIX}HeroAltDescriptionSection`,
                    heroAltSectionData: {
                        ...item,
                        nextSectionType: `${PREFIX}DescriptionSection`,
                        order: i + 1,
                    },
                    descriptionSectionData: {
                        ...data.datoCmsPage.sections[i + 1],
                        nextSectionType: data.datoCmsPage.sections[i + 2]
                            ? data.datoCmsPage.sections[i + 2].__typename
                            : null,
                        order: i + 2,
                    },
                });
                skipNext = true;
            } else if (!skipNext) {
                finalSections.push(item);
            } else {
                skipNext = false;
            }
        });

        return finalSections;
    }, data?.datoCmsPage?.sections);

    return (
        <Layout navbarFixed={true} navbarTransparent={true}>
            {sections.map((section, i) => (
                <Section
                    key={i}
                    order={i + 1}
                    isLast={i + 1 === sections.length}
                    previousSectionType={i ? sections[i - 1].__typename : null}
                    nextSectionType={
                        sections.length > i + 1
                            ? sections[i + 1].__typename
                            : null
                    }
                    {...section}
                />
            ))}
        </Layout>
    );
}

export const query = graphql`
    fragment Button on DatoCmsButton {
        link {
            slug
        }
        label
        id
        detached
        externalLink
    }
    query Page($id: String, $language: String) {
        datoCmsPage(id: { eq: $id }, locale: { eq: $language }) {
            title
            slug
            treeParent {
                title
                slug
                treeParent {
                    title
                    slug
                    treeParent {
                        title
                        slug
                    }
                }
            }
            sections {
                ... on DatoCmsHeroSection {
                    ...DatoCmsHeroSection
                }
                ... on DatoCmsHeroSectionAlt {
                    ...DatoCmsHeroSectionAlt
                }
                ... on DatoCmsPointSection {
                    ...DatoCmsPointSection
                }
                ... on DatoCmsValueSection {
                    ...DatoCmsValueSection
                }
                ... on DatoCmsFeatureCarouselSection {
                    ...DatoCmsFeatureCarouselSection
                }
                ... on DatoCmsFeatureSection {
                    ...DatoCmsFeatureSection
                }
                ... on DatoCmsFeatureDetailedSection {
                    ...DatoCmsFeatureDetailedSection
                }
                ... on DatoCmsPortfolioSection {
                    ...DatoCmsPortfolioSection
                }
                ... on DatoCmsTeamSection {
                    ...DatoCmsTeamSection
                }
                ... on DatoCmsDescriptionSection {
                    ...DatoCmsDescriptionSection
                }
                ... on DatoCmsHtmlSection {
                    ...DatoCmsHtmlSection
                }
                ... on DatoCmsHighlightedSection {
                    ...DatoCmsHighlightedSection
                }
                ... on DatoCmsPortfolioFilterSection {
                    ...DatoCmsPortfolioFilterSection
                }
                ... on DatoCmsTeamFilterSection {
                    ...DatoCmsTeamFilterSection
                }
                ... on DatoCmsVideoSection {
                    ...DatoCmsVideoSection
                }
                ... on DatoCmsFastFactsSection {
                    ...DatoCmsFastFactsSection
                }
                ... on DatoCmsFastFactsSectionIi {
                    ...DatoCmsFastFactsSectionIi
                }
                ... on DatoCmsWorldmapsection {
                    ...DatoCmsWorldmapsection
                }
            }
        }
    }
`;
