/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';

export const PREFIX = `DatoCms`;

interface SectionProps {
    __typename: string;
}

export default function Section({
    __typename: type,
    ...props
}: SectionProps): JSX.Element {
    let SectionComponent = () => <div> -- Section {type} not found -- </div>;

    switch (type) {
        case `${PREFIX}HeroSection`:
            SectionComponent = require('./components/HeroSection').default;
            break;
        case `${PREFIX}HeroSectionAlt`:
            SectionComponent = require('./components/HeroSectionAlt').default;
            break;
        case `${PREFIX}PointSection`:
            SectionComponent = require('./components/PointSection').default;
            break;
        case `${PREFIX}ValueSection`:
            SectionComponent = require('./components/ValueSection').default;
            break;
        case `${PREFIX}DescriptionSection`:
            SectionComponent = require('./components/DescriptionSection')
                .default;
            break;
        case `${PREFIX}FeatureCarouselSection`:
            SectionComponent = require('./components/FeatureCarouselSection')
                .default;
            break;
        case `${PREFIX}FeatureSection`:
            SectionComponent = require('./components/FeatureSection').default;
            break;
        case `${PREFIX}FeatureDetailedSection`:
            SectionComponent = require('./components/FeatureDetailedSection')
                .default;
            break;
        case `${PREFIX}PortfolioSection`:
            SectionComponent = require('./components/PortfolioSection').default;
            break;
        case `${PREFIX}PortfolioFilterSection`:
            SectionComponent = require('./components/PortfolioFilterSection')
                .default;
            break;
        case `${PREFIX}TeamFilterSection`:
            SectionComponent = require('./components/TeamFilterSection')
                .default;
            break;
        case `${PREFIX}TeamSection`:
            SectionComponent = require('./components/TeamSection').default;
            break;
        case `${PREFIX}HtmlSection`:
            SectionComponent = require('./components/HtmlSection').default;
            break;
        case `${PREFIX}HighlightedSection`:
            SectionComponent = require('./components/HighlightedSection')
                .default;
            break;
        case `${PREFIX}HeroAltDescriptionSection`:
            SectionComponent = require('./components/HeroAltDescriptionSection')
                .default;
            break;
        case `${PREFIX}VideoSection`:
            SectionComponent = require('./components/VideoSection').default;
            break;
        case `${PREFIX}FastFactsSection`:
            SectionComponent = require('./components/FastFactsSection').default;
            break;
        case `${PREFIX}FastFactsSectionIi`:
            SectionComponent = require('./components/FastFactsSectionII')
                .default;
            break;
        case `${PREFIX}Worldmapsection`:
            SectionComponent = require('./components/WorldMapSection').default;
            break;
        default:
            break;
    }

    return <SectionComponent {...props} />;
}
