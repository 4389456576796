import React from 'react';
import { graphql } from 'gatsby';
import { FastFactsSectionII } from '@solublestudio/idc-design-system';

export default FastFactsSectionII;

export const query = graphql`
    fragment DatoCmsFastFactsSectionIi on DatoCmsFastFactsSectionIi {
        title
        items {
          logo
          title
          subtitle
          size
          linkImage
          isexit
          investmentAreas
          description
          websiteurl
        }
    }
`;
