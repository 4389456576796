import React from 'react';
import { graphql } from 'gatsby';
import { VideoSection } from '@solublestudio/idc-design-system';

export default VideoSection;

export const query = graphql`
    fragment DatoCmsVideoSection on DatoCmsVideoSection {
        variant,
        title,
        subtitle,
        urlVideo,
    }
`;
