import React from 'react';
import { PortfolioListSection as PSection } from '@solublestudio/idc-design-system';
import { graphql } from 'gatsby';
import { PortfolioItem } from '../PortfolioSection';
import { useTranslate } from '@solublestudio/gatsby-theme-soluble-source';

export default function PortfolioFilterSection({
    results,
    ...props
}: {
    results: PortfolioItem[];
}): JSX.Element {
    const _ = useTranslate();
    return (
        <PSection
            {...props}
            allCategoriesText={_['filter.all-categories'].text}
            portfolio={results}
        />
    );
}

export const query = graphql`
    fragment DatoCmsPortfolioFilterSection on DatoCmsPortfolioFilterSection {
        results {
            ...PortfolioItem
            categories {
                slug
            }
        }
        categories {
            title
            slug
        }
    }
`;
