import React from 'react';
import { graphql } from 'gatsby';
import { HeroSectionAlt as HeroAlt } from '@solublestudio/idc-design-system';
export interface HeroSectionAltProps {
    title: string;
    variant: string;
    nextSectionType: string;
}

export default function HeroSectionAlt({
    variant = 'primary',
    ...props
}: HeroSectionAltProps): JSX.Element {
    return <HeroAlt {...props} variant={variant} />;
}

export const query = graphql`
    fragment DatoCmsHeroSectionAlt on DatoCmsHeroSectionAlt {
        title
        variant
        titleHidden
    }
`;
