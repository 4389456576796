import React from 'react';
import { graphql } from 'gatsby';
import { PointSection as PSection } from '@solublestudio/idc-design-system';

interface PointSectionProps {
    points: { title: string; number: string; description: string }[];
}

export default function PointSection(props: PointSectionProps): JSX.Element {
    return <PSection points={props.points || []} />;
}

export const query = graphql`
    fragment DatoCmsPointSection on DatoCmsPointSection {
        id
        points {
            ...Point
        }
    }
`;
