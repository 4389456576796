import React from 'react';
import { HeroAltDescriptionSection as DSSection } from '@solublestudio/idc-design-system';
import { HeroSectionAltProps } from '../HeroSectionAlt';
import { DescriptionSectionProps } from '../DescriptionSection';

interface HeroAltDescriptionSectionProps {
    heroAltSectionData: HeroSectionAltProps;
    descriptionSectionData: DescriptionSectionProps;
    order: number;
}

export default function HeroAltDescriptionSection(
    props: HeroAltDescriptionSectionProps,
): JSX.Element {
    return <DSSection {...props} />;
}
