import React from 'react';
import { graphql } from 'gatsby';

import { HTMLSection as HTMLSection } from '@solublestudio/idc-design-system';

export default HTMLSection;

export const query = graphql`
    fragment DatoCmsHtmlSection on DatoCmsHtmlSection {
        text
    }
`;
