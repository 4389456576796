import React from 'react';
import { graphql } from 'gatsby';
import { ValuesSection as VSection } from '@solublestudio/idc-design-system';

interface ValueSectionProps {
    title: string;
    points: { title: string; number: string; description: string }[];
    picture: object;
}

export default function ValueSection(props: ValueSectionProps): JSX.Element {
    return (
        <VSection
            points={props.points}
            title={props.title}
            picture={props.picture}
        />
    );
}

export const query = graphql`
    fragment Point on DatoCmsPoint {
        title
        number
        description
    }

    fragment DatoCmsValueSection on DatoCmsValueSection {
        title
        points {
            ...Point
        }
        picture {
            file {
                publicURL
                extension
                childImageSharp {
                    fluid(
                        maxWidth: 1280
                        traceSVG: { color: "#252AD4", background: "#EDECE6" }
                    ) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
            isImage
            width
            height
        }
    }
`;
