import React from 'react';
import { HighlightedSection as HSection } from '@solublestudio/idc-design-system';
import { graphql } from 'gatsby';

interface DescriptionSectionProps {
    image: string;
    text: string;
    companyName: string;
    icon: string;
    externalLink: string;
    previousSectionType: string;
}

export default function HighlightedSection(
    props: DescriptionSectionProps,
): JSX.Element {
    return <HSection {...props} />;
}
export const query = graphql`
    fragment DatoCmsHighlightedSection on DatoCmsHighlightedSection {
        image {
            file {
                publicURL
                childImageSharp {
                    fluid(
                        maxWidth: 1280
                        traceSVG: { color: "#252AD4", background: "#EDECE6" }
                    ) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
        companyName
        icon {
            file {
                publicURL
                childImageSharp {
                    resize(height: 192) {
                        src
                    }
                }
            }
        }
        text
        externalLink
    }
`;
