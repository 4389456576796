import React from 'react';
import { PortfolioListSection as PSection } from '@solublestudio/idc-design-system';
import { graphql } from 'gatsby';

export type PortfolioItem = {
    companyName: string;
};

export default PSection;

export const query = graphql`
    fragment PortfolioItem on DatoCmsPortfolio {
        companyName
        category
        title
        subtitle
        region
        isexit
        externalLink
        video {
            url
        }
        showSecondaryIcon
        primaryIcon {
            file {
                publicURL
                childImageSharp {
                    resize(height: 192) {
                        src
                    }
                }
            }
        }
        secondaryIcon {
            file {
                publicURL
                childImageSharp {
                    resize(height: 192) {
                        src
                    }
                }
            }
        }
    }
    fragment DatoCmsPortfolioSection on DatoCmsPortfolioSection {
        pretitle
        portfolio {
            ...PortfolioItem
        }
        compact
        image1 {
            file {
                publicURL
                childImageSharp {
                    fluid(
                        maxWidth: 1280
                        traceSVG: { color: "#252AD4", background: "#EDECE6" }
                    ) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
        image2 {
            file {
                publicURL
                childImageSharp {
                    fluid(
                        maxWidth: 1280
                        traceSVG: { color: "#252AD4", background: "#EDECE6" }
                    ) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    }
`;
