import React from 'react';
import { graphql } from 'gatsby';
import { FeatureDetailedSection as FDSection } from '@solublestudio/idc-design-system';

export default FDSection;

export const query = graphql`
    fragment DatoCmsFeatureDetailedSection on DatoCmsFeatureDetailedSection {
        preTitle
        title
        description
        ctas {
            ...Button
        }
        picture {
            file {
                publicURL
                childImageSharp {
                    fixed(
                        width: 1184
                        height: 1566
                        traceSVG: { color: "#252AD4", background: "#EDECE6" }
                    ) {
                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                }
            }
        }
    }
`;
