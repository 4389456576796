import React from 'react';
import { graphql } from 'gatsby';
import { WorldMapSection } from '@solublestudio/idc-design-system';

export default WorldMapSection;

export const query = graphql`
    fragment DatoCmsWorldmapsection on DatoCmsWorldmapsection {
        showworldmap
        companies {
            companyName
            isexit
            iso3166
            region
            primaryIcon {
                file {
                    publicURL
                    childImageSharp {
                        resize(height: 192) {
                            src
                        }
                    }
                }
            }
            secondaryIcon {
                file {
                    publicURL
                    childImageSharp {
                        resize(height: 192) {
                            src
                        }
                    }
                }
            }
        }
        markers {
            name
            longitude
            latitude
        }
    }
`;
