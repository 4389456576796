import React from 'react';
import { graphql } from 'gatsby';

import { FeatureSection as FSection } from '@solublestudio/idc-design-system';

export default FSection;

export const query = graphql`
    fragment DatoCmsFeatureSection on DatoCmsFeatureSection {
        pretitle
        title
        description
        picture {
            file {
                publicURL
                childImageSharp {
                    fixed(
                        width: 1184
                        height: 1342
                        traceSVG: { color: "#252AD4", background: "#EDECE6" }
                    ) {
                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                }
                publicURL
                extension
            }
            isImage
            width
            height
        }
    }
`;
