import React from 'react';
import { DescriptionSection as DSection } from '@solublestudio/idc-design-system';
import { graphql } from 'gatsby';

export interface DescriptionSectionProps {
    pretitle: string;
    title: string;
    order: number;
    nextSectionType: string;
}

export default function DescriptionSection(
    props: DescriptionSectionProps,
): JSX.Element {
    return <DSection {...props} />;
}

export const query = graphql`
    fragment DatoCmsDescriptionSection on DatoCmsDescriptionSection {
        pretitle
        title
    }
`;
