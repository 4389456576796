import React from 'react';
import { graphql } from 'gatsby';
import { FastFactsSection } from '@solublestudio/idc-design-system';

export default FastFactsSection;

export const query = graphql`
    fragment DatoCmsFastFactsSection on DatoCmsFastFactsSection {
      title
      items {
        title
        subtitle
        body {
          value
        }
      }
    }
`;
