import React from 'react';
import { graphql } from 'gatsby';
import { FeatureCarouselSection as FCSection } from '@solublestudio/idc-design-system';

export default FCSection;
export const query = graphql`
    fragment DatoCmsFeatureCarouselSection on DatoCmsFeatureCarouselSection {
        preTitle
        title
        description
        tags
        items {
            text
            picture {
                file {
                    publicURL
                    childImageSharp {
                        fixed(width: 608, height: 488) {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
            }
        }
        cta {
            ...Button
        }
    }
`;
