import React from 'react';
import { graphql } from 'gatsby';
import { HeroSection } from '@solublestudio/idc-design-system';

export default HeroSection;

export const query = graphql`
    fragment DatoCmsHeroSection on DatoCmsHeroSection {
        title
        subtitle
        description
        ispopupactive
        popuptitle
        popuptext {
            value
        }
        popupbuttontext
        popupbuttonlink
        picture {
            file {
                childImageSharp {
                    fixed(
                        width: 1200
                        height: 600
                        traceSVG: { color: "#252AD4", background: "#EDECE6" }
                    ) {
                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                }
                publicURL
                extension
            }
            isImage
            width
            height
        }
    }
`;
