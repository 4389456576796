import React, { useCallback } from 'react';
import { graphql } from 'gatsby';

import { TeamList as TeamListDS } from '@solublestudio/idc-design-system';

export type Member = {
    name: string;
    charge: string;
    linkedin: string;
    cv: string;
    image: any;
};

interface TeamSectionCategory {
    title: string;
    slug: string;
}

interface TeamSectionProps {
    teamMember: Member[];
    title: string;
    order: number;
    categories: TeamSectionCategory[];
}

export default function TeamSection({
    teamMember,
    order,
    ...props
}: TeamSectionProps): JSX.Element {
    return (
        <TeamListDS
            {...props}
            teamMembers={teamMember}
            variant={order >= 3 ? 'secondary' : 'primary'}
        />
    );
}

export const query = graphql`
    fragment TeamItem on DatoCmsTeamMember {
        name
        charge
        linkedin
        entity
        cv
        image {
            file {
                publicURL
                childImageSharp {
                    fixed(
                        width: 800
                        height: 800
                        traceSVG: { color: "#252AD4", background: "#EDECE6" }
                    ) {
                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                }
            }
        }
    }
    fragment DatoCmsTeamSection on DatoCmsTeamSection {
        teamMember {
            ...TeamItem
        }
        title
        pretitle
    }
`;
